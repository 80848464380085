<template>
  <sdDrawer
      ref="SupplierAddRef"
      :form="form"
      title="Leverancier aanmaken"
      type="submit"
      btnText="Toevoegen"
      submitBtnText="Opslaan"
  >
    <FormValidationWrap>
      <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" @finish="CreateSupplier">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Naam" name="name">
              <a-input v-model:value="form.name" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Weergavenaam" name="displayName">
              <a-input v-model:value="form.displayName" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="E-mailadres" name="email">
              <a-input v-model:value="form.email" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Telefoonnummer" name="phoneNumber">
              <a-input type="number" v-model:value="form.phoneNumber" placeholder=""
                       onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                       onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Postcode" name="postalCode">
              <a-input v-model:value="form.postalCode" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Huisnummer" name="houseNumber">
              <a-input type="number" v-model:value="form.houseNumber" placeholder=""
                       onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                       onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"/>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="Toevoeging" name="houseNumberAddition">
              <a-input v-model:value="form.houseNumberAddition" placeholder=""/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="10">
            <a-form-item label="Straat" name="street">
              <a-input v-model:value="form.street" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="Plaats" name="city">
              <a-input v-model:value="form.city" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="Land" name="country">
              <a-select v-model:value="form.country" size="large" name="country" class="sDash_fullwidth-select"
                        :allowClear="true">
                <a-select-option v-for="(item,index) in countryOptions" :key="index" :value="item.value">
                  {{ item.key }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button size="large" class="btn-signin" type="primary" html-type="submit" :loading="loadingCreateSupplier"
                    :disabled="loadingCreateSupplier"> Opslaan
          </a-button>
        </div>
      </a-form>
    </FormValidationWrap>
  </sdDrawer>
</template>
<script>
import {computed, defineComponent, reactive, ref} from 'vue';
import {FormValidationWrap} from "../styled";
import {useStore} from "vuex";
import {countryOptions} from "../../utility/enums";
import {
  cocNumberValidator,
  phoneNumberValidator,
  postalCodeValidator,
  websiteValidator
} from "../../utility/validators";

const SupplierAdd = defineComponent({
  name: 'SupplierAdd',
  components: {
    FormValidationWrap,
  },
  setup() {
    const SupplierAddRef = ref();
    const formRef = ref();
    const {state, dispatch} = useStore();
    const loadingCreateSupplier = computed(() => state.team.loadingCreateSupplier);
    const form = reactive({
      city: null,
      cocNumber: null,
      country: null,
      displayName: null,
      email: null,
      houseNumber: null,
      houseNumberAddition: null,
      name: null,
      phoneNumber: null,
      postalCode: null,
      street: null,
      website: null,
    });
    const rules = {
      name: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      displayName: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      website: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
        {
          validator: websiteValidator,
        }
      ],
      cocNumber: [
        {
          required: false,
          message: 'Dit veld is verplicht',
        },
        {
          validator: cocNumberValidator,
        }
      ],
      phoneNumber: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
        {
          validator: phoneNumberValidator,
        },
      ],
      email: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      houseNumber: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      postalCode: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
        {
          validator: postalCodeValidator,
        },
      ],
      country: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      city: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
    };
    const closeDrawer = () => {
      SupplierAddRef.value.onClose();
      formRef.value.resetFields();
      dispatch('getSuppliers');
    }
    const CreateSupplier = () => {
       dispatch('createSupplier', {value: form, close: closeDrawer});
    };
    return {
      form,
      rules,
      CreateSupplier,
      loadingCreateSupplier,
      countryOptions,
      SupplierAddRef,
      formRef,
    };
  },
});

export default SupplierAdd;
</script>
