<template>
  <TableStyleWrapper>
    <TableWrapper class="table-responsive">
      <a-table
          :loading="loadingSuppliers"
          :dataSource="supplierTableData"
          :columns="supplierTableColumns"
          :pagination="{
          defaultPageSize: 10,
          total: count,
          current: currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
          @change="paginationChange"
      />
    </TableWrapper>
  </TableStyleWrapper>
</template>
<script>
import {TableStyleWrapper} from '../style';
import {TableWrapper} from '../../styled';
import {computed, defineComponent} from 'vue';
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import moment from "moment";
import {useAbility} from "@casl/vue";

const supplierTableColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Naam',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Aangemaakt op',
    dataIndex: 'createTimestamp',
    key: 'createTimestamp',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];
const SupplierListTable = defineComponent({
  name: 'SupplierListTable',
  components: {TableStyleWrapper, TableWrapper},
  setup() {
    const router = useRouter();
    const {state, dispatch} = useStore();
    const {matched} = useRoute();
    const {path} = matched[1];
    const suppliers = computed(() => state.supplier.suppliers);
    const loadingSuppliers = computed(() => state.supplier.loadingSuppliers);
    const currentPage = computed(() => state.supplier.currentPage);
    const count = computed(() => state.supplier.count);
    const { can } = useAbility();
    const handleDelete = async (id) => {
      await dispatch('deleteSupplier', id);
      await dispatch('getSuppliers', {page: state.supplier.current, pageSize: state.supplier.pageSize});
    };
    const handleEdit = (id) => {
      router.push(`${path}/${id}`);
    };
    const supplierTableData = computed(() =>
        suppliers.value.map((supplier) => {
          const {id, name, description, createTimestamp} = supplier;

          return {
            key: id,
            id: id,
            name: (
                <div class="user-info">
                  <figcaption>
                    <sdHeading class="user-name" as="h6">
                      {name}
                    </sdHeading>
                    <span class="user-designation">{description}</span>
                  </figcaption>
                </div>
            ),
            createTimestamp: <span>{moment(createTimestamp).format('DD-MM-YYYY HH:mm:ss')}</span>,
            action: (
                <div className="table-actions">
                  {can('view', 'supplier') ? (
                      <sdButton onClick={() => handleEdit(id)} className="btn-icon" to="#" type="default" shape="circle">
                        <sdFeatherIcons type="edit" size="16"/>
                      </sdButton>
                  ) : ('')}
                  {can('delete', 'supplier') ? (
                      <a-popconfirm
                          title="Weet je het zeker? U kunt dit niet meer terugdraaien!"
                          ok-text="Ja"
                          cancel-text="Nee"
                          placement="leftTop"
                          onConfirm={() => handleDelete(id)}
                      >
                        <sdButton className="btn-icon" type="default" to="#" shape="circle">
                          <sdFeatherIcons type="trash-2" size="16"/>
                        </sdButton>
                      </a-popconfirm>
                  ) : ('')}
                </div>
            ),
          };
        }),
    );

    const rowSelection = {
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled supplier', // Column configuration not to be checked
        name: record.name,
      }),
    };

    const paginationChange = async (event) => {
      await dispatch('setCurrentPageSupplier', event.current);
      await dispatch('getSuppliers', {page: event.current, pageSize: state.supplier.pageSize});
    }

    return {supplierTableColumns, supplierTableData, rowSelection, loadingSuppliers, paginationChange, currentPage, count};
  },
});

export default SupplierListTable;
</script>
